import {
  Card,
  Box,
  CardContent,
  Typography,
  Stack,
  CardMedia,
  Icon,
} from "@mui/material";

import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import SquareFootIcon from "@mui/icons-material/SquareFoot";

export const Display = (props: any) => {
  if (!props.renderCardsData) return;

  const cards = props.renderCardsData;

  return cards.map((card: any) => (
    <Box key={card.id}>
      <Card
        sx={{
          maxWidth: 345,
          textAlign: "left",
          borderRadius: "10px",
        }}
      >
        <CardMedia component="img" image={card.src} />
        <CardContent>
          <Typography color="primary" gutterBottom variant="h6" component="div">
            <Stack direction="row" alignItems="center">
              ${card.price}/<Typography>month</Typography>
            </Stack>
          </Typography>

          <Typography variant="h4">{card.name}</Typography>
          <Typography variant="body2" gutterBottom mb={2}>
            {`${card.address.street}, ${card.address.city}, ${card.address.state}`}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Stack direction="row">
              <Icon color="primary">
                <BedIcon />
              </Icon>
              <Typography>{card.beds} Beds</Typography>
            </Stack>
            <Stack direction="row">
              <Icon color="primary">
                <ShowerIcon />
              </Icon>
              <Typography>{card.bathrooms} Bathrooms</Typography>
            </Stack>
            <Stack direction="row">
              <Icon color="primary">
                <SquareFootIcon />
              </Icon>
              <Typography>
                {card.area} m<sup>2</sup>
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  ));
};
