import React from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
  Button,
} from "@mui/material";

import DraftsIcon from "@mui/icons-material/Drafts";

export const NavBar = () => {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="logo"
          >
            <DraftsIcon />
          </IconButton>
          <Typography variant="h6" component="div" mr={5}>
            Estatery
          </Typography>

          <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
            <Button color="inherit">Rent</Button>
            <Button color="inherit">Buy</Button>
            <Button color="inherit">Sell</Button>
            <Button color="inherit">Manage Property</Button>
            <Button color="inherit">Resources</Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button color="inherit" variant="outlined">
              Login
            </Button>
            <Button color="inherit" variant="outlined">
              Sign up
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </div>
  );
};
