export const realEstateData = [
  {
    src: "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "The old steele",
    price: 1600,
    address: {
      street: "103 Lake Shores",
      city: "Michigan",
      state: "IN",
    },
    beds: 3,
    bathrooms: 1,
    area: "5x7",
    id: 0,
    type: "house", // 0 = house type
    availability: new Date("October 13, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Faulkner Ave",
    price: 4500,
    address: {
      street: "909 Woodland St",
      city: "Michigan",
      state: "IN",
    },
    beds: 4,
    bathrooms: 3,
    area: "8x10",
    id: 1,
    type: "house", // 0 = house type
    availability: new Date("August 30, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/53610/large-home-residential-house-architecture-53610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Beverly Springfield",
    price: 2700,
    address: {
      street: "2821 Lake Sevilla",
      city: "Palm Harbor",
      state: "TX",
    },
    beds: 4,
    bathrooms: 2,
    area: "6x7.5",
    id: 2,
    type: "villa", // 1 = villa type
    availability: new Date("September 30, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/210617/pexels-photo-210617.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Palm Harbor",
    price: 2095,
    address: {
      street: "210 US Highway",
      city: "Highland Lake",
      state: "FL",
    },
    beds: 3,
    bathrooms: 2,
    area: "5x7",
    id: 3,
    type: "house", // 0 = house type
    availability: new Date("November 13, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/259588/pexels-photo-259588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "St. Crystal",
    price: 2400,
    address: {
      street: "210 US Highway",
      city: "Highland Lake",
      state: "FL",
    },
    beds: 4,
    bathrooms: 2,
    area: "6x8",
    id: 4,
    type: "house", // 0 = house type
    availability: new Date("August 05, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Cove Red",
    price: 1500,
    address: {
      street: "243 Curlew Road",
      city: "Palm Harbor",
      state: "TX",
    },
    beds: 2,
    bathrooms: 1,
    area: "5x7.5",
    id: 5,
    type: "house", // 0 = house type
    availability: new Date("September 25, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/53610/large-home-residential-house-architecture-53610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "The old steele",
    price: 1600,
    address: {
      street: "103 Lake Shores",
      city: "Michigan",
      state: "IN",
    },
    beds: 3,
    bathrooms: 1,
    area: "5x7",
    id: 6,
    type: "villa", // 1 = villa type
    availability: new Date("August 01, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Tarpon Bay",
    price: 1600,
    address: {
      street: "103 Lake Shores",
      city: "Michigan",
      state: "IN",
    },
    beds: 3,
    bathrooms: 1,
    area: "5x7",
    id: 7,
    type: "house", // 0 = house type
    availability: new Date("September 18, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/280222/pexels-photo-280222.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Cove Red",
    price: 1500,
    address: {
      street: "243 Curlew Road",
      city: "Palm Harbor",
      state: "TX",
    },
    beds: 2,
    bathrooms: 1,
    area: "5x7.5",
    id: 8,
    type: "house", // 0 = house type
    availability: new Date("October 02, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/1396132/pexels-photo-1396132.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Palm Harbor",
    price: 2095,
    address: {
      street: "2699 Green Valley",
      city: "Highland Lake",
      state: "FL",
    },
    beds: 3,
    bathrooms: 2,
    area: "5x7",
    id: 9,
    type: "house", // 0 = house type
    availability: new Date("August 28, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/210617/pexels-photo-210617.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "The old steele",
    price: 1600,
    address: { street: "103 Lake Shores", city: "Michigan", state: "IN" },
    beds: 3,
    bathrooms: 1,
    area: "5x7",
    id: 10,
    type: "house", // 0 = house type
    availability: new Date("December 20, 2022"),
  },
  {
    src: "https://images.pexels.com/photos/53610/large-home-residential-house-architecture-53610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Tarpon Bay",
    price: 1600,
    address: { street: "103 Lake Shores", city: "Michigan", state: "IN" },
    beds: 3,
    bathrooms: 1,
    area: "5x7",
    id: 11,
    type: "villa", // 1 = villa type
    availability: new Date("October 22, 2022"),
  },
];

export const priceRange = [
  {
    value: 1000,
    label: "$1k",
  },
  {
    value: 2000,
    label: "$2K",
  },
  {
    value: 3000,
    label: "$3K",
  },
  {
    value: 4000,
    label: "$4K",
  },
  {
    value: 5000,
    label: "$5K",
  },
];
