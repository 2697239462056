import {
  Container,
  Stack,
  Box,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Button,
  Slider,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// Date pickers
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useState } from "react";

import { realEstateData } from "../../data.js";

import { Display } from "../display/Display";

export const Dashboard = () => {
  const initialState = {
    propertyType: "",
    selectedDate: null,
    price: [1000, 5000],
    location: "",
  };

  const [state, setState] = useState<any>(initialState);

  const [renderCardsData, setRenderCardsData] = useState<{}>(realEstateData);

  // Filter search function
  const handleFilterChange = () => {
    if (state === initialState) {
      return;
    } else {
      const filteredData = realEstateData.filter((data) => {
        return (
          state.propertyType === data.type &&
          state.price[0] <= data.price &&
          state.price[1] >= data.price &&
          state.location === data.address.state &&
          state.selectedDate.getTime() > data.availability.getTime()
        );
      });
      console.log(filteredData);
      setRenderCardsData(filteredData);
    }
  };

  const handleChangeProperty = (event: SelectChangeEvent<string>) => {
    setState({ ...state, propertyType: event.target.value as string });
  };

  const handleChangePrice = (event: Event, newValue: number | number[]) => {
    setState({ ...state, price: newValue as number[] });
  };

  const handleChangeDate = (newValue: Date | null) => {
    setState({ ...state, selectedDate: newValue });
  };

  const handleChangeLocation = (event: SelectChangeEvent<any>) => {
    setState({ ...state, location: event.target.value });
  };

  const handleReset = () => {
    setState(initialState);
    setRenderCardsData(realEstateData);
  };

  // Function to fetch unique state names
  const fetchStateNames = () => {
    const stateNames: [] | any = [];
    realEstateData.forEach((data) => {
      stateNames.push(data.address.state);
    });

    const stateNamesUnique = new Set(stateNames);
    return Array.from(stateNamesUnique);
  };

  // Variable to store unique state names
  const stateNamesArray: [] | any = fetchStateNames();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Stack direction="row" pt={5} pb={5} justifyContent="space-between">
          <Typography variant="h2">Search properties to rent</Typography>
          <TextField type="text" label="Search..." />
        </Stack>

        <Stack mb={5}>
          <Paper elevation={2}>
            <Stack
              direction="row"
              p={2}
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* LOCATION FILTER */}
              <FormControl fullWidth>
                <InputLabel id="locationLabel">Location</InputLabel>
                <Select
                  value={state.location}
                  label="Location"
                  labelId="locationLabel"
                  onChange={handleChangeLocation}
                >
                  {stateNamesArray.map((name: string) => {
                    return (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Select Location</FormHelperText>
              </FormControl>

              {/* DATE FILTER */}
              <FormControl fullWidth>
                <DatePicker
                  label="Date Picker"
                  value={state.selectedDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => (
                    <TextField {...params} label="When" />
                  )}
                />
                <FormHelperText>Select Move-in Date</FormHelperText>
              </FormControl>

              {/* PROPERTY TYPE FILTER */}
              <FormControl fullWidth>
                <InputLabel id="propertyTypeLabel">Property Type</InputLabel>
                <Select
                  labelId="propertyTypeLabel"
                  defaultChecked
                  value={state.propertyType}
                  label="Property Type"
                  onChange={handleChangeProperty}
                >
                  <MenuItem value={"house"}>House</MenuItem>
                  <MenuItem value={"villa"}>Villa</MenuItem>
                </Select>
                <FormHelperText>Select Property Type</FormHelperText>
              </FormControl>

              {/* PRICE FILTER */}
              <FormControl fullWidth>
                <Stack direction="row" spacing={1}>
                  <FormControl>
                    <TextField
                      value={state.price[0]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      // onChange={(event) =>
                      //   setState({
                      //     ...state,
                      //     price: [event.target.value, ...state.price[1]],
                      //   })
                      // }
                    />
                    <FormHelperText>Min Price</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <TextField
                      value={state.price[1]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      // onChange={(event) =>
                      //   setState({
                      //     ...state,
                      //     price: [...state.price[0], event.target.value],
                      //   })
                      // }
                    />

                    <FormHelperText>Max Price</FormHelperText>
                  </FormControl>
                </Stack>
                <Slider
                  sx={{ padding: 0, margin: 0 }}
                  max={5000}
                  min={1000}
                  size="small"
                  value={state.price}
                  onChange={handleChangePrice}
                />
              </FormControl>

              {/* SEARCH AND RESET BUTTONS */}
              <Stack>
                {/* Search Button */}
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleFilterChange}
                >
                  Search
                </Button>

                {/* Remove filters button */}
                <Button
                  disableFocusRipple
                  size="small"
                  onClick={handleReset}
                  endIcon={<FilterAltOffIcon />}
                  sx={{
                    color: "#000",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>

        <Stack>
          <Box
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gap={2}
            justifyItems="center"
            alignItems="center"
          >
            <Display renderCardsData={renderCardsData} />
          </Box>
        </Stack>
      </Container>
    </LocalizationProvider>
  );
};
